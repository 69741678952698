import './Start.css'


export default function Start() {
  return (
    <div className='StartDiv'>
        <img className="winImg" src='https://github.com/Jacobdye99/Portfolio/blob/dev/client/src/Assets/Images/artage-io-48148_1646464545.png?raw=true' alt='windows 95' />
        <h3 className='StartText'>Start</h3>
    </div>
  )
}
